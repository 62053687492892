<template>
  <main-layout>
    <b-row>
      <b-col>
        <h3>Iniciar sesión</h3>
      </b-col>
    </b-row>
    <b-form @submit="onSubmit">
      <b-row>
        <b-col>
          <b-form-group id="username-group" label="Usuario:" label-for="id">
            <b-form-input id="id" size="sm" v-model="form.id" type="text" @keypress="numbersOnly" required
                          placeholder="Id usuario"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="password-group" label="Contraseña:" label-for="password">
            <b-form-input id="password" size="sm" v-model="form.password" type="password" required
                          placeholder="Contraseña usuario"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col align="center">
          <b-button size="sm" type="submit">Ingresar</b-button>
        </b-col>
      </b-row>
    </b-form>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import axios from "axios";

export default {
  name: 'LoginForm',
  components: {
    MainLayout
  },
  data() {
    return {
      form: {
        id: '',
        password: ''
      }
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      axios
      .post(process.env.VUE_APP_BACKEND_URL + '/sale-consultants/login', this.form)
      .then(() => {
        this.$router.push({path: '/affiliation-list?saleConsultant=' + this.form.id})
      })
      .catch(error => {
        console.log('Get sale consultant error: ' + error)
        if (error.response.status === 403) {
          this.$bvModal.msgBoxOk('Las credenciales de acceso no son validas')
        }
        if (error.response.status === 404) {
          this.$bvModal.msgBoxOk('Consultor de venta no encontrado')
        }
      })
    },
    numbersOnly(event) {
      event = (event) ? event : window.event;
      const charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        event.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>