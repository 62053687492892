<template>
  <main-layout>
    <b-row>
      <b-col align="center">
        <h1>Afiliaciones</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <router-link :to="{path: '/affiliations?saleConsultant=' + this.saleConsultant }"><h5>Nueva</h5></router-link>
      </b-col>
    </b-row>
    <b-row v-show="affiliationConfirmer">
      <b-col>
        <router-link :to="{path: '/affiliation-confirm?saleConsultant=' + this.saleConsultant }"><h5>Confirmar</h5></router-link>
      </b-col>
    </b-row>
    <div v-if="affiliations.length > 0">
      <b-row>
        <b-col>
          <h3>&nbsp;</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table-simple striped hover small>
            <b-thead>
              <b-tr>
                <b-th>ID</b-th>
                <b-th>Confirmada</b-th>
                <b-th>ID Afiliado</b-th>
                <b-th>Nombre</b-th>
                <b-th>Apellido</b-th>
                <b-th>Fecha Nacimiento</b-th>
                <b-th>Dirección</b-th>
                <b-th>Estado</b-th>
                <b-th>Ciudad</b-th>
                <b-th>Condado</b-th>
                <b-th>Zip</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(af, index) in affiliations" :key="index">
              <b-tr>
                <b-td>
                  <router-link :to="{ path: '/affiliations/' + af.id }">{{ af.id }}</router-link>
                </b-td>
                <b-td>{{ af.confirmed ? 'Si' : 'No' }}</b-td>
                <b-td>{{ af.affiliate.id }}</b-td>
                <b-td>{{ af.affiliate.firstName }}</b-td>
                <b-td>{{ af.affiliate.lastName }}</b-td>
                <b-td>{{ af.affiliate.birthDate }}</b-td>
                <b-td>{{ af.affiliate.address.name }}</b-td>
                <b-td>{{ af.affiliate.address.state }}</b-td>
                <b-td>{{ af.affiliate.address.city }}</b-td>
                <b-td>{{ af.affiliate.address.county }}</b-td>
                <b-td>{{ af.affiliate.address.zipcode }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import axios from "axios";

export default {
  name: 'LoginForm',
  components: {
    MainLayout
  },
  data() {
    return {
      saleConsultant: '',
      affiliationConfirmer: false,
      affiliations: []
    }
  },
  mounted() {
    axios
        .get(process.env.VUE_APP_BACKEND_URL + '/affiliations?saleConsultant=' + this.$route.query.saleConsultant)
        .then(response => {
          this.affiliations = response.data
          this.saleConsultant = this.$route.query.saleConsultant
        })
        .catch(error => {
          console.log('Get affiliations by sale consultant error: ' + error)
        })
    axios
        .get(process.env.VUE_APP_BACKEND_URL + '/sale-consultants/' + this.$route.query.saleConsultant)
        .then(response => {
          this.affiliationConfirmer = response.data.affiliationConfirmer
        })
        .catch(error => {
          console.log('Get sale consultant error: ' + error)
        })
  }
}
</script>