<template>
  <main-layout>
    <div>
      <b-form @submit="onSubmit">
        <!-------------------------------->
        <!-------------------------------->
        <!-------------------------------->
        <b-row>
          <b-col align="center">
            <h1>Confirmar afiliación</h1>
          </b-col>
        </b-row>
        <div>
          <b-row>
            <b-col>
              <h3>&nbsp;</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="conf-affiliation-group" label="ID:" label-for="affiliationId">
                <b-form-input id="affiliationId" size="sm" v-model="form.confirmation.affiliationId" type="text"
                              placeholder="ID afiliación" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="conf-agent-group" label="Agente:" label-for="policyAgentName">
                <b-form-select id="policyAgentName" size="sm" v-model="form.confirmation.confirmedBy"
                               :options="options.policyAgents"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-row align="center">
            <b-col>
              <b-button size="sm" type="submit">Confirmar</b-button>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import axios from "axios";

export default {
  name: 'AffiliationConfirm',
  components: {
    MainLayout
  },
  data() {
    return {
      form: {
        confirmation: {
          affiliationId: '',
          confirmedBy: 0,
          saleConsultant: {
            id: 0
          }
        }
      },
      options: {
        policyAgents: []
      }
    }
  },
  mounted() {
    this.form.confirmation.saleConsultant.id = this.$route.query.saleConsultant
    this.loadPolicyAgents()
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      axios
          .post(process.env.VUE_APP_BACKEND_URL + '/affiliations/confirm', this.form.confirmation)
          .then(() => {
            this.$router.push({path: '/affiliation-list?saleConsultant=' + this.form.confirmation.saleConsultant.id})
          })
          .catch(error => {
            if (error.response.status === 409) {
              this.$bvModal.msgBoxOk('Esta afiliación ya fue confirmada previamente')
            } else {
              console.log('Confirm affiliation error: ' + error)
              this.$bvModal.msgBoxOk('Error al confirmar la afiliación')
            }
          })
    },
    loadPolicyAgents() {
      this.options.policyAgents = []
      axios
          .get(process.env.VUE_APP_BACKEND_URL + '/policy-agents')
          .then(response => {
            this.zipcodes = response.data
            response.data.forEach(sc => this.options.policyAgents.push({
              value: sc.id, text: sc.firstName + ' ' + sc.lastName
            }))
          })
          .catch(error => {
            console.log('Get policy agents error: ' + error)
          })
    }
  }
}
</script>