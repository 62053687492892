<template>
  <main-layout>
    <div>
      <b-form @submit="onSubmit">
        <!-------------------------------->
        <!-------------------------------->
        <!-------------------------------->
        <b-row>
          <b-col align="center" v-show="!updateMode">
            <h1>Nueva afiliación</h1>
          </b-col>
          <b-col align="center" v-show="updateMode">
            <h1>Actualizar afiliación</h1>
          </b-col>
        </b-row>
        <b-row v-show="updateMode">
          <b-col sm="3">
            <b>ID:</b>
          </b-col>
          <b-col>
            {{ affiliationId }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="3">
            <b>Consultor:</b>
          </b-col>
          <b-col>
            {{ saleConsultant.firstName }} {{ saleConsultant.lastName }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="3">
            <b>Total afiliados mes:</b>
          </b-col>
          <b-col>
            {{ saleConsultant.affiliatesThisMonth }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="3">
            <b>Total beneficiarios mes:</b>
          </b-col>
          <b-col>
            {{ saleConsultant.beneficiariesThisMonth }}
          </b-col>
        </b-row>
        <!-------------------------------->
        <!-- AffiliateData -->
        <!-------------------------------->
        <div>
          <b-row>
            <b-col>
              <h3>Afiliado</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="aff-first-name-group" label="Nombre:" label-for="firstName">
                <b-form-input id="firstName" size="sm" v-model="form.affiliate.firstName" type="text"
                              placeholder="Nombre afiliado" :required="!updateMode" :disabled="updateMode"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-second-name-group" label="Segundo nombre:" label-for="secondName">
                <b-form-input id="secondName" size="sm" v-model="form.affiliate.secondName" type="text"
                              placeholder="Segundo nombre afiliado" :disabled="updateMode">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-last-name-group" label="Apellido:" label-for="lastName">
                <b-form-input id="lastName" size="sm" v-model="form.affiliate.lastName" type="text"
                              placeholder="Apellido afiliado" :required="!updateMode" :disabled="updateMode">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-second-last-name-group" label="Segundo apellido:" label-for="secondLastName">
                <b-form-input id="secondLastName" size="sm" v-model="form.affiliate.secondLastName" type="text"
                              placeholder="Segundo apellido afiliado" :disabled="updateMode"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="aff-birth-date-group" label="Fecha nacimiento:" label-for="birthDate">
                <b-form-datepicker id="birthDate" size="sm" v-model="form.affiliate.birthDate"
                                   :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                   locale="es" label-no-date-selected="Seleccione" :required="!updateMode" :disabled="updateMode"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-gender-group" label="Genero:" label-for="gender">
                <b-form-select id="gender" size="sm" v-model="form.affiliate.gender" :options="options.gender"
                               :required="!updateMode" :disabled="updateMode"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-birth-country-group" label="País de nacimiento:" label-for="birthCountry">
                <b-form-select id="birthCountry" size="sm" v-model="form.affiliate.birthPlace.countryId"
                               :options="options.countries" :required="!updateMode" :disabled="updateMode"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-marital-status-group" label="Estado civil:" label-for="maritalStatus">
                <b-form-select id="maritalStatus" size="sm" v-model="form.affiliate.maritalStatus"
                               :options="options.maritalStatus" :required="!updateMode" :disabled="updateMode"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="3">
              <b-form-group id="aff-address-name-group" label="Dirección de residencia:" label-for="addressName">
                <b-form-input id="addressName" size="sm" v-model="form.affiliate.address.name" type="text"
                              placeholder="Dirección afiliado" :required="!updateMode" :disabled="updateMode"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group id="aff-address-state-group" label="Estado:" label-for="addressState">
                <b-form-select id="addressState" size="sm" v-model="form.affiliate.address.stateId"
                               :options="options.states" @change="loadZipcodes"
                               :required="!updateMode" :disabled="updateMode"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group id="aff-address-zipcode-group" label="Zip:" label-for="addressZip">
                <b-form-select id="addressZip" size="sm" v-model="form.affiliate.address.zipcodeId"
                               :options="options.zipcodes" @change="loadZipcodeDetails"
                               :required="!updateMode" :disabled="updateMode"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group id="aff-address-city-group" label="Ciudad:" label-for="addressCity">
                <b-form-input id="addressCity" size="sm" v-model="address.cityName" type="text" disabled></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group id="aff-address-county-group" label="Condado:" label-for="addressCounty">
                <b-form-input id="addressCounty" size="sm" v-model="address.countyName" type="text"
                              disabled></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group id="aff-phone-group" label="Teléfono:" label-for="phone">
                <b-form-input id="phone" size="sm" v-model="form.affiliate.phone" type="text"
                              placeholder="Teléfono afiliado" :required="!updateMode" :disabled="updateMode"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-email-group" label="Correo electrónico:" label-for="email">
                <b-form-input id="email" size="sm" v-model="form.affiliate.email" type="email"
                              placeholder="Correo afiliado" :disabled="updateMode"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-fam-members-group" label="Miembros de la familia:" label-for="familyMembers">
                <b-form-input id="familyMembers" size="sm" v-model="form.affiliate.numberOfFamilyMembers" type="number"
                              @keypress="numbersOnly"
                              placeholder="Número de miembros" :required="!updateMode" :disabled="updateMode"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-income-group" label="Ingresos anuales:" label-for="annualIncome">
                <b-form-input id="annualIncome" size="sm" v-model="form.affiliate.annualIncome" type="number"
                              placeholder="Ingresos" :required="!updateMode" :disabled="updateMode"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-ssn-group" label="Social:" label-for="ssn">
                <b-form-input id="ssn" size="sm" v-model="form.affiliate.ssn" type="text"
                              placeholder="SSN" :disabled="updateMode"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <!-------------------------------->
          <!-- InsuranceData -->
          <!-------------------------------->
          <b-row>
            <b-col>
              <h3>Aseguradora</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="aff-ins-company-group" label="Nombre:" label-for="companyName">
                <b-form-select id="companyName" size="sm" v-model="form.insurance.companyId"
                               :options="options.insuranceCompanies" :required="!updateMode" :disabled="updateMode"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-ins-plan-group" label="Plan:" label-for="planName">
                <b-form-select id="planName" size="sm" v-model="form.insurance.planId"
                               :options="options.insurancePlans" :required="!updateMode" :disabled="updateMode"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <!-------------------------------->
          <!-- BankData -->
          <!-------------------------------->
          <b-row>
            <b-col>
              <h3>Datos bancarios</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="aff-bank-name-group" label="Banco:" label-for="bankName">
                <b-form-input id="bankName" size="sm" v-model="form.bank.name" type="text"
                              placeholder="Nombre banco"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-routing-number-group" label="Número de ruta:" label-for="routingNumber">
                <b-form-input id="routingNumber" size="sm" v-model="form.bank.routingNumber" type="text"
                              placeholder="Num ruta banco"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-account-number-group" label="Número de cuenta:" label-for="accountNumber">
                <b-form-input id="accountNumber" size="sm" v-model="form.bank.accountNumber" type="text"
                              placeholder="Cuenta banco"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="aff-card-franchise-group" label="Franquicia de tarjeta:" label-for="cardFranchise">
                <b-form-select id="cardFranchise" size="sm" v-model="form.bank.card.franchise"
                               :options="options.cardFranchise"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-card-number-group" label="Número de tarjeta:" label-for="cardNumber">
                <b-form-input id="cardNumber" size="sm" v-model="form.bank.card.number" type="number"
                              @keypress="numbersOnly"
                              placeholder="Num tarjeta"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-card-exp-month-group" label="Mes de vencimiento:" label-for="cardExpMonth">
                <b-form-input id="cardExpMonth" size="sm" v-model="form.bank.card.expiryMonth" type="number"
                              @keypress="numbersOnly"
                              placeholder="Mes"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-card-exp-year-group" label="Año de vencimiento:" label-for="cardExpYear">
                <b-form-input id="cardExpYear" size="sm" v-model="form.bank.card.expiryYear" type="number"
                              @keypress="numbersOnly"
                              placeholder="Año"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-card-cvv-group" label="CVV:" label-for="cardCvv">
                <b-form-input id="cardCvv" size="sm" v-model="form.bank.card.cvv" type="number" @keypress="numbersOnly"
                              placeholder="CVV"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <!-------------------------------->
          <!-- BeneficiaryData -->
          <!-------------------------------->
          <b-row>
            <b-col>
              <h3>Beneficiarios</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="aff-benef-first-name-group" label="Nombre:" label-for="beneficiaryFirstName">
                <b-form-input id="beneficiaryFirstName" size="sm" v-model="beneficiary.firstName" type="text"
                              placeholder="Nombre beneficiario"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-benef-second-name-group" label="Segundo Nombre:" label-for="beneficiarySecondName">
                <b-form-input id="beneficiarySecondName" size="sm" v-model="beneficiary.secondName" type="text"
                              placeholder="Segundo nombre beneficiario"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-benef-last-name-group" label="Apellido:" label-for="beneficiaryLastName">
                <b-form-input id="beneficiaryLastName" size="sm" v-model="beneficiary.lastName" type="text"
                              placeholder="Apellido beneficiario"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-benef-second-last-name-group" label="Segundo apellido:"
                            label-for="beneficiarySecondLastName">
                <b-form-input id="beneficiarySecondLastName" size="sm" v-model="beneficiary.secondLastName" type="text"
                              placeholder="Segundo apellido beneficiario"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="aff-benef-birth-date-group" label="Fecha nacimiento:" label-for="beneficiaryBirthDate">
                <b-form-datepicker id="beneficiaryBirthDate" size="sm" v-model="beneficiary.birthDate"
                                   :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                   locale="es" label-no-date-selected="Seleccione"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-benef-gender-group" label="Genero:" label-for="beneficiaryGender">
                <b-form-select id="beneficiaryGender" size="sm" v-model="beneficiary.gender"
                               :options="options.gender"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-benef-type-group" label="Tipo:" label-for="beneficiaryType">
                <b-form-select id="beneficiaryType" size="sm" v-model="beneficiary.type"
                               :options="options.beneficiaryTypes"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="aff-benef-relationship-group" label="Relación con afiliado:"
                            label-for="beneficiaryRelationship">
                <b-form-select id="beneficiaryRelationship" size="sm" v-model="beneficiary.relationshipWithAffiliate"
                               :options="options.relationshipWithAffilate"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button size="sm" v-on:click="addBeneficiary">Agregar</b-button>&nbsp;
            </b-col>
          </b-row>

          <div id="beneficiariesTable" v-if="form.affiliate.beneficiaries.length > 0">
            <b-row>
              <b-col>
                <b-table-simple striped hover small>
                  <b-thead>
                    <b-tr>
                      <b-th>Nombre(s)</b-th>
                      <b-th>Apellido(s)</b-th>
                      <b-th>Fecha nacimiento</b-th>
                      <b-th>Genero</b-th>
                      <b-th>Tipo</b-th>
                      <b-th>Relación con afiliado</b-th>
                      <b-th>&nbsp;</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody v-for="(bf, index) in form.affiliate.beneficiaries" :key="index">
                    <b-tr>
                      <b-td>{{ bf.firstName }} {{ bf.secondName }}</b-td>
                      <b-td>{{ bf.lastName }} {{ bf.secondLastName }}</b-td>
                      <b-td>{{ bf.birthDate }}</b-td>
                      <b-td>{{ bf.gender }}</b-td>
                      <b-td>{{ bf.type }}</b-td>
                      <b-td>{{ bf.relationshipWithAffiliate }}</b-td>
                      <b-td>
                        <b-button size="sm" v-on:click="removeBeneficiary(index)">Quitar</b-button>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </div>

          
          <!-------------------------------->
          <!-- Comment -->
          <!-------------------------------->
          <b-row>
            <b-col>
              <h3>Comentarios</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-textarea id="comment" size="sm" v-model="form.comment" rows="3" max-rows="6"></b-form-textarea>
            </b-col>
          </b-row>

          <div style="margin: 220px">
            <b-row align="center">
              <b-col>
                <b-button size="sm" type="submit">Guardar</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-form>
    </div>
  </main-layout>
</template>

<script>
import axios from 'axios'
import MainLayout from "@/layouts/MainLayout.vue";

export default {
  name: 'AffiliationForm',
  props: ['id'],
  components: {
    MainLayout
  },
  data() {
    return {
      form: {
        saleConsultant: {
          id: 0
        },
        affiliate: {
          firstName: '',
          secondName: '',
          lastName: '',
          secondLastName: '',
          birthDate: '',
          birthPlace: {
            countryId: 0
          },
          gender: '',
          maritalStatus: '',
          address: {
            name: '',
            stateId: 0,
            cityId: 0,
            countyId: 0,
            zipcodeId: 0
          },
          phone: '',
          email: '',
          numberOfFamilyMembers: 0,
          annualIncome: 0,
          ssn: '',
          beneficiaries: []
        },
        insurance: {
          companyId: 0,
          planId: 0
        },
        bank: {
          name: '',
          routingNumber: '',
          accountNumber: '',
          card: {
            franchise: '',
            number: '',
            expiryMonth: '',
            expiryYear: '',
            cvv: ''
          }
        },
        comment: ''
      },
      options: {
        gender: [
          {value: 'M', text: 'Masculino'},
          {value: 'F', text: 'Femenino'}
        ],
        maritalStatus: [
          {value: 'Casado/a', text: 'Casado/a'},
          {value: 'Soltero/a', text: 'Soltero/a'}
        ],
        cardFranchise: [
          {value: 'VISA', text: 'VISA'},
          {value: 'MC', text: 'MasterCard'},
          {value: 'AMEX', text: 'American Express'}
        ],
        beneficiaryTypes: [
          {value: 'Conyuge', text: 'Conyuge'},
          {value: 'Beneficiario', text: 'Beneficiario'}
        ],
        relationshipWithAffilate: [
          {value: 'Conyuge', text: 'Conyuge'},
          {value: 'Padre', text: 'Padre'},
          {value: 'Madre', text: 'Madre'},
          {value: 'Hermano/a', text: 'Hermano/a'},
          {value: 'Hijo/a', text: 'Hijo/a'},
          {value: 'Abuelo/a', text: 'Abuelo/a'},
          {value: 'Primo/a', text: 'Primo/a'},
          {value: 'Tio/a', text: 'Tio/a'},
          {value: 'Nieto/a', text: 'Nieto/a'},
          {value: 'Sobrino/a', text: 'Sobrino/a'},
          {value: 'Suegro/a', text: 'Suegro/a'},
          {value: 'Yerno/Nuera', text: 'Yerno/Nuera'},
        ],
        insuranceCompanies: [],
        insurancePlans: [],
        countries: [],
        cities: [],
        states: [],
        counties: [],
        zipcodes: []
      },
      beneficiary: {
        firstName: '',
        secondName: '',
        lastName: '',
        secondLastName: '',
        birthDate: '',
        gender: '',
        type: '',
        relationshipWithAffiliate: ''
      },
      saleConsultant: {
        firstName: '',
        lastName: '',
        affiliatesThisMonth: 0,
        beneficiariesThisMonth: 0
      },
      address: {
        cityName: '',
        countyName: ''
      },
      zipcodes: [],
      updateMode: false,
      affiliationId: '',
    };
  },
  methods: {
    numbersOnly(event) {
      event = (event) ? event : window.event;
      const charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        event.preventDefault()
      } else {
        return true
      }
    },
    onSubmit(event) {
      event.preventDefault()
      if (this.updateMode) {
        axios
            .put(process.env.VUE_APP_BACKEND_URL + '/affiliations/' + this.affiliationId, this.form)
            .then(() => {
              this.$router.push({path: '/affiliation-list?saleConsultant=' + this.form.saleConsultant.id})
            })
            .catch(error => {
              console.log('Update affiliation error: ' + error)
              this.$bvModal.msgBoxOk('Error al actualizar la afiliación')
            })
      } else {
        axios
            .post(process.env.VUE_APP_BACKEND_URL + '/affiliations', this.form)
            .then(() => {
              this.$router.push({path: '/affiliation-list?saleConsultant=' + this.form.saleConsultant.id})
            })
            .catch(error => {
              console.log('Create affiliation error: ' + error)
              this.$bvModal.msgBoxOk('Error al registrar la afiliación')
            })
      }
    },
    addBeneficiary() {
      if (this.beneficiary.firstName !== '' && this.beneficiary.lastName !== '' && this.beneficiary.birthDate !== ''
          && this.beneficiary.gender !== '' && this.beneficiary.type !== '' && this.beneficiary.relationshipWithAffiliate !== '') {

        const existing = this.form.affiliate.beneficiaries.filter((b) => {
          if (b.firstName === this.beneficiary.firstName && b.lastName === this.beneficiary.lastName
              && b.secondLastName === this.beneficiary.secondLastName && b.birthDate === this.beneficiary.birthDate) {
            return b
          }
        })

        if (existing.length > 0) {
          this.$bvModal.msgBoxOk('Ya se agregó un beneficiario con esa información')
        } else {
          this.form.affiliate.beneficiaries.push({
            index: this.form.affiliate.beneficiaries.length + 1,
            firstName: this.beneficiary.firstName,
            secondName: this.beneficiary.secondName,
            lastName: this.beneficiary.lastName,
            secondLastName: this.beneficiary.secondLastName,
            birthDate: this.beneficiary.birthDate,
            gender: this.beneficiary.gender,
            type: this.beneficiary.type,
            relationshipWithAffiliate: this.beneficiary.relationshipWithAffiliate
          })
          this.beneficiary = {
            firstName: '',
            secondName: '',
            lastName: '',
            secondLastName: '',
            birthDate: '',
            gender: '',
            type: '',
            relationshipWithAffiliate: ''
          }
        }
      } else {
        this.$bvModal.msgBoxOk('Se debe diligenciar toda la información del beneficiario')
      }
    },
    removeBeneficiary(index) {
      this.form.affiliate.beneficiaries.splice(index, 1)
    },
    loadAffiliation() {
      if (this.$props.id) {
        axios
            .get(process.env.VUE_APP_BACKEND_URL + '/affiliations/' + this.$props.id)
            .then(response => {
              this.form = response.data
              this.updateMode = true
              this.form.saleConsultant.id = response.data.saleConsultant.id
              this.affiliationId = this.$props.id
              this.loadSaleConsultant()
              this.loadZipcodes()
            })
            .catch(error => {
              console.log('Get affiliation error: ' + error)
            })
      } else {
        this.form.saleConsultant.id = this.$route.query.saleConsultant
        this.loadSaleConsultant()
      }
    },
    loadSaleConsultant() {
      axios
          .get(process.env.VUE_APP_BACKEND_URL + '/sale-consultants/' + this.form.saleConsultant.id)
          .then(response => {
            this.saleConsultant.firstName = response.data.firstName
            this.saleConsultant.lastName = response.data.lastName
            this.saleConsultant.affiliatesThisMonth = response.data.affiliatesThisMonth
            this.saleConsultant.beneficiariesThisMonth = response.data.beneficiariesThisMonth
          })
          .catch(error => {
            console.log('Get sale consultant error: ' + error)
            this.$router.push({path: '/login'})
          })
    },
    loadZipcodes() {
      this.options.zipcodes = []
      this.zipcodes = []
      this.form.affiliate.address.cityId = 0
      this.address.cityName = ''
      this.form.affiliate.address.countyId = 0
      this.address.countyName = ''
      axios
          .get(process.env.VUE_APP_BACKEND_URL + '/zipcodes?stateId=' + this.form.affiliate.address.stateId)
          .then(response => {
            this.zipcodes = response.data
            response.data.forEach(z => this.options.zipcodes.push({
              value: z.id, text: z.code
            }))
            if (this.updateMode) {
              this.loadZipcodeDetails()
            }
          })
          .catch(error => {
            console.log('Get zipcodes error: ' + error)
          })
    },
    loadZipcodeDetails() {
      const zipcode = this.zipcodes.find(z => z.id === this.form.affiliate.address.zipcodeId)
      this.form.affiliate.address.cityId = zipcode.cityId
      this.address.cityName = zipcode.cityName
      this.form.affiliate.address.countyId = zipcode.countyId
      this.address.countyName = zipcode.countyName
    }
  },
  mounted() {
    this.loadAffiliation()

    axios
        .get(process.env.VUE_APP_BACKEND_URL + '/countries')
        .then(response => response.data.forEach(r => this.options.countries.push({
          value: r.id, text: r.name
        })))
        .catch(error => {
          console.log('Get countries error: ' + error)
        })

    axios
        .get(process.env.VUE_APP_BACKEND_URL + '/states?countryId=11')
        .then(response => response.data.forEach(r => this.options.states.push({
          value: r.id, text: r.name
        })))
        .catch(error => {
          console.log('Get states error: ' + error)
        })

    axios
        .get(process.env.VUE_APP_BACKEND_URL + '/insurance-companies')
        .then(response => response.data.forEach(r => this.options.insuranceCompanies.push({
          value: r.id, text: r.name
        })))
        .catch(error => {
          console.log('Get insurance companies error: ' + error)
        })

    axios
        .get(process.env.VUE_APP_BACKEND_URL + '/insurance-plans')
        .then(response => response.data.forEach(r => this.options.insurancePlans.push({
          value: r.id, text: r.name
        })))
        .catch(error => {
          console.log('Get insurance plans error: ' + error)
        })
  }
};
</script>