import LoginForm from "@/components/Login.vue";
import AffiliationForm from "@/components/AffiliationForm.vue";
import VueRouter from "vue-router";
import Vue from "vue";
import AffiliationList from "@/components/AffiliationList.vue";
import AffiliationConfirm from "@/components/AffiliationConfirm.vue";

const routes = [
  {path: '/', redirect: '/login'},
  {path: '/login', component: LoginForm},
  {path: '/affiliation-list', component: AffiliationList},
  {path: '/affiliations', component: AffiliationForm},
  {path: '/affiliations/:id', component: AffiliationForm, props: true},
  {path: '/affiliation-confirm', component: AffiliationConfirm}
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

Vue.use(VueRouter)

export default router